import axios from "axios";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
require("dotenv").config();

export interface WorksiteState {
  worksite: {
    id: number;
    name: string;
    startDate: Date;
    companyLogo: string;
    backgroundColor: string;
  };
  holidays: {
    startDate: Date;
    endDate: Date;
    description: string;
    id: string;
    static: boolean;
    workday: boolean;
  }[];
  accidents: { created_at: string; description: string; id: number }[];
  urlParams: {
    userId: string;
    worksiteId: string;
  };
  loading: boolean;
  loadingL10n: boolean;
  error: boolean;
  locale: string;
  l10n: any;
}

const initState: WorksiteState = {
  holidays: [],
  accidents: [],
  worksite: {
    id: 0,
    name: "-",
    startDate: DateTime.local().toJSDate(),
    backgroundColor: "#fbd38d",
    companyLogo: "",
  },
  urlParams: { userId: "", worksiteId: "" },
  loading: true,
  loadingL10n: true,
  error: false,
  locale: "",
  l10n: {},
};

const getStaticHolidays = (locale: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const language = localStorage.getItem("language") ?? locale;
    if (language === "fi_FI") {
      resolve(
        axios.get(
          "https://" +
            process.env.REACT_APP_DOMAIN +
            "/lib/fi_holidays_and_eves_2020-12-22.json"
        )
      );
    } else {
      // en_GB
      resolve(
        axios.get(
          "https://" +
            process.env.REACT_APP_DOMAIN +
            "/lib/en_holidays_and_eves_2020_12_22.json"
        )
      );
    }
  });
};

export const asyncGetWorksite = createAsyncThunk(
  "worksite/getWorksiteAsync",
  async (worksiteId: number, thunkApi: any) => {
    const state = await thunkApi.getState();
    const res = await axios.get(
      process.env.REACT_APP_BASE_URL +
        "/worksite/" +
        state.worksite.urlParams.worksiteId +
        "/" +
        state.worksite.urlParams.userId
    );

    if (res.data.worksite.showDefaultHolidays === "1") {
      const static_holidays = await getStaticHolidays(
        res.data.worksite.language
      );

      const parsed_static_holidays = static_holidays.data.map((i: any) => {
        return {
          description: i.description,
          startDate: i.date,
          endDate: i.date,
          static: true,
        };
      });

      return {
        ...res.data,
        holidays: [...res.data.holidays, ...parsed_static_holidays],
      };
    } else {
      return res.data;
    }
  }
);

export const asyncGetLocale = createAsyncThunk(
  "worksite/getLocaleasync",
  async () => {
    const res = await axios.get(
      "https://" + process.env.REACT_APP_DOMAIN + "/lib/localization/l10n.json"
    );
    return res.data;
  }
);

export const asyncPostHoliday = createAsyncThunk(
  "worksite/postHoliday",
  async (
    holiday: {
      start: string;
      end: string;
      description: string;
      workday: string;
    },
    thunkApi: any
  ) => {
    const state = thunkApi.getState();

    const res = await axios.post(
      process.env.REACT_APP_BASE_URL +
        "/worksite/" +
        state.worksite.urlParams.worksiteId +
        "/" +
        state.worksite.urlParams.userId,
      { holiday: holiday }
    );

    if (state.worksite.worksite.showDefaultHolidays === "1") {
      const static_holidays = await getStaticHolidays(state.worksite.locale);

      const parsed_static_holidays = static_holidays.data.map((i: any) => {
        return {
          description: i.description,
          startDate: i.date,
          endDate: i.date,
          static: true,
        };
      });

      return {
        // ...res.data,
        holidays: [...res.data.holidays, ...parsed_static_holidays],
      };
    } else {
      return res.data;
    }
  }
);

export const asyncDeleteHoliday = createAsyncThunk(
  "worksite/deleteHoliday",
  async (holidayId: number, thunkApi: any) => {
    const state = thunkApi.getState();
    const res = await axios.delete(
      process.env.REACT_APP_BASE_URL +
        "/worksite/" +
        state.worksite.urlParams.worksiteId +
        "/" +
        state.worksite.urlParams.userId,
      {
        params: {
          holidayId: holidayId,
        },
      }
    );

    if (state.worksite.worksite.showDefaultHolidays === "1") {
      const static_holidays = await getStaticHolidays(state.worksite.locale);

      const parsed_static_holidays = static_holidays.data.map((i: any) => {
        return {
          description: i.description,
          startDate: i.date,
          endDate: i.date,
          static: true,
        };
      });

      return {
        ...res.data,
        holidays: [...res.data.holidays, ...parsed_static_holidays],
      };
    } else {
      return res.data;
    }
  }
);

export const asyncPostAccident = createAsyncThunk(
  "worksite/postAccident",
  async (accident: { date: string; description: string }, thunkApi: any) => {
    const state = thunkApi.getState();
    const res = await axios.post(
      process.env.REACT_APP_BASE_URL +
        "/worksite/" +
        state.worksite.urlParams.worksiteId +
        "/" +
        state.worksite.urlParams.userId,
      { accident: accident }
    );

    return res.data;
  }
);

export const asyncDeleteAccident = createAsyncThunk(
  "worksite/deleteAccident",
  async (accidentId: number, thunkApi: any) => {
    const state = thunkApi.getState();
    const res = await axios.delete(
      process.env.REACT_APP_BASE_URL +
        "/worksite/" +
        state.worksite.urlParams.worksiteId +
        "/" +
        state.worksite.urlParams.userId,
      {
        params: {
          accidentId: accidentId,
        },
      }
    );

    return res.data;
  }
);

export const asyncChangeStartDate = createAsyncThunk(
  "worksite/changeStartDate",
  async (startDate: Date, thunkApi: any) => {
    const state = thunkApi.getState();
    const res = await axios.patch(
      process.env.REACT_APP_BASE_URL +
        "/worksite/" +
        state.worksite.urlParams.worksiteId +
        "/" +
        state.worksite.urlParams.userId,
      {
        worksiteStartDate: startDate,
      }
    );

    if (state.worksite.worksite.showDefaultHolidays === "1") {
      const static_holidays = await getStaticHolidays(state.worksite.locale);

      const parsed_static_holidays = static_holidays.data.map((i: any) => {
        return {
          description: i.description,
          startDate: i.date,
          endDate: i.date,
          static: true,
        };
      });

      return {
        ...res.data,
        holidays: [...res.data.holidays, ...parsed_static_holidays],
      };
    } else {
      return res.data;
    }
  }
);

export const worksiteSlice = createSlice({
  name: "worksite",
  initialState: initState,
  reducers: {
    getWorksite: (
      state,
      action: PayloadAction<{ worksite: WorksiteState }>
    ) => {
      state = action.payload.worksite;
    },
    setUrlParams: (
      state,
      action: PayloadAction<{ userId: string; worksiteId: string }>
    ) => {
      state.urlParams = action.payload;
    },
    changeLanguage: (state, action: PayloadAction<string>) => {
      localStorage.setItem("language", action.payload);
      state.locale = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetWorksite.fulfilled, (state, action) => {
      state.holidays = action.payload.holidays.map((i: any) => {
        return {
          startDate: DateTime.fromISO(i.startDate).toJSDate(),
          endDate: DateTime.fromISO(i.endDate).toJSDate(),
          description: i.description,
          static: i.static ?? false,
          workday: i.workday,
          id: i.id,
        };
      });
      state.loading = false;
      state.accidents = action.payload.accidents;
      state.locale =
        localStorage.getItem("language") ?? action.payload.worksite.language;
      state.worksite = {
        ...action.payload.worksite,
        startDate: DateTime.fromISO(
          action.payload.worksite.startDate
        ).toJSDate(),
      };
    });
    builder.addCase(asyncGetWorksite.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(asyncPostHoliday.fulfilled, (state, action) => {
      state.holidays = action.payload.holidays.map((i: any) => {
        return {
          startDate: DateTime.fromISO(i.startDate).toJSDate(),
          endDate: DateTime.fromISO(i.endDate).toJSDate(),
          description: i.description,
          static: i.static,
          id: i.id,
          workday: i.workday,
        };
      });
    });
    builder.addCase(asyncDeleteHoliday.fulfilled, (state, action) => {
      state.holidays = action.payload.holidays.map((i: any) => {
        return {
          startDate: DateTime.fromISO(i.startDate).toJSDate(),
          endDate: DateTime.fromISO(i.endDate).toJSDate(),
          description: i.description,
          static: i.static,
          id: i.id,
          workday: i.workday,
        };
      });
    });
    builder.addCase(asyncPostAccident.fulfilled, (state, action) => {
      state.accidents = action.payload.accidents;
    });
    builder.addCase(asyncDeleteAccident.fulfilled, (state, action) => {
      state.accidents = action.payload.accidents;
    });
    builder.addCase(asyncChangeStartDate.fulfilled, (state, action) => {
      state.worksite.startDate = DateTime.fromISO(
        action.payload.worksite.startDate
      ).toJSDate();
    });
    builder.addCase(asyncGetLocale.fulfilled, (state, action) => {
      state.l10n = action.payload;
      state.loadingL10n = false;
    });
  },
});

export const { setUrlParams, changeLanguage } = worksiteSlice.actions;

export default worksiteSlice.reducer;
