import React from "react";
import { Box } from "@chakra-ui/core";

import _ from "../lib/localisation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { changeLanguage } from "../reducers/worksiteReducer";

const Footer = () => {
  const locale = useSelector((state: RootState) => state.worksite.locale);
  const dispatch = useDispatch();

  return (
    <>
      <Box
        maxWidth="40rem"
        margin="auto"
        alignItems="center"
        textAlign="center"
        justifyContent="flex-start"
        paddingBottom="2rem"
      >
        {locale !== "fi_FI" ? (
          <div
            className="langChange"
            onClick={() => {
              dispatch(changeLanguage("fi_FI"));
              console.log("change to finnish");
              // eslint-disable-next-line no-restricted-globals
              location.reload();
            }}
          >
            {_("Suomeksi?")}
          </div>
        ) : (
          ""
        )}
        {locale !== "en_GB" ? (
          <div
            className="langChange"
            onClick={() => {
              dispatch(changeLanguage("en_GB"));
              console.log("change to english");
              // eslint-disable-next-line no-restricted-globals
              location.reload();
            }}
          >
            {_("In English?")}
          </div>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};

export default Footer;
