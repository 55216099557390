import { configureStore } from "@reduxjs/toolkit";
import worksiteReducer from "./reducers/worksiteReducer";

const store = configureStore({
  reducer: {
    worksite: worksiteReducer,
  },
});

export default store;

const storeState = store.getState();

export type RootState = typeof storeState;
