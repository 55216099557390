import { Box, Flex, Icon, Text } from "@chakra-ui/core";
import React, { useState } from "react";
import _ from "../lib/localisation";
import Accidents from "./Accidents";
import CalendarView from "./Calendar";

const ControlTabs = () => {
  const [route, setRoute] = useState<"injury" | "calendar">("injury");

  return (
    <Box maxWidth="40rem" margin="auto" padding="1rem" mt={["2rem", "4rem"]}>
      <Flex>
        <Box
          onClick={() => {
            setRoute("injury");
          }}
          height={"8rem"}
          border={0}
          mr={2}
          p={4}
          rounded="lg"
          bg="white"
          shadow="md"
          flex={1}
        >
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            height={"100%"}
            color={route === "injury" ? "#3182ce" : "black"}
          >
            <Icon name="warning-2" size="2rem" />
            <Text textAlign="center" mt={4}>
              {_("Add an accident")}
            </Text>
          </Flex>
        </Box>
        <Box
          onClick={() => {
            setRoute("calendar");
          }}
          height={"8rem"}
          border={0}
          ml={2}
          p={4}
          rounded="lg"
          bg="white"
          shadow="md"
          flex={1}
        >
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            height={"100%"}
            color={route === "calendar" ? "#3182ce" : "black"}
          >
            <Icon name="calendar" size="2rem" />
            <Text textAlign="center" mt={4}>
              {_("Modify calendar")}
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Box rounded={"lg"} bg="white" p={4} mt={4}>
        {route === "injury" ? <Accidents /> : <CalendarView />}
      </Box>
    </Box>
  );
};

export default ControlTabs;
