import {
  Box,
  Flex,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  Text,
  Button,
  Divider,
} from "@chakra-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncDeleteAccident,
  asyncPostAccident,
} from "../reducers/worksiteReducer";
import { RootState } from "../store";
import "./datepicker.css";
import DatePicker from "react-datepicker";
import fi from "date-fns/locale/fi";
import enGB from "date-fns/locale/en-GB";
import { registerLocale } from "react-datepicker";
import { DateTime } from "luxon";
import _ from "../lib/localisation";
registerLocale("fi_FI", fi);
registerLocale("en_GB", enGB);

const Accidents = () => {
  const accidents = useSelector((state: RootState) => state.worksite.accidents);
  const dispatch = useDispatch();

  const descriptionRef = React.useRef<any>();

  const [accidentDate, setAccidentDate] = useState<any>(null);

  const locale = useSelector((state: RootState) => state.worksite.locale);

  return (
    <>
      <Box>
        <Text fontSize="xl" mt={0} fontWeight="bold">
          {_("Accidents")}
        </Text>
        <Box>
          {accidents.map((i) => {
            return (
              <Box key={`accident-${i.id}`}>
                <Flex
                  justifyContent="space-between"
                  shadow="md"
                  alignItems="center"
                  padding="1rem"
                  bg="white"
                  my="1rem"
                >
                  <Box>
                    <Text my={2}>{i.description}</Text>
                    <Text my={2}>
                      {i.created_at ? i.created_at.slice(0, 10) : ""}
                    </Text>
                  </Box>
                  <Box>
                    <IconButton
                      aria-label="Delete"
                      icon="delete"
                      variant="outline"
                      bg="white"
                      onClick={() => {
                        dispatch(asyncDeleteAccident(i.id));
                      }}
                    />
                  </Box>
                </Flex>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Divider my={8} />

      <Text fontSize="xl" fontWeight="bold" mt={0}>
        {_("Add an accident")}
      </Text>
      <Flex
        alignItems="center"
        flexWrap="wrap"
        direction="row"
        justifyContent="space-between"
      >
        <Box pr={4} mr={4}>
          <FormControl>
            <FormLabel htmlFor="description">{_("Description")}</FormLabel>
            <Input
              ref={descriptionRef}
              size="md"
              type="description"
              id="description"
              aria-describedby="description-helper-text"
            />
          </FormControl>
        </Box>
        <Box pr={4} mr={4}>
          <FormControl>
            <FormLabel htmlFor="date">{_("Date")}</FormLabel>
            <br />
            <DatePicker
              locale={locale}
              // locale="fi-FI"
              dateFormat="dd.MM.yyyy"
              selected={accidentDate}
              onChange={(date) => setAccidentDate(date)}
            />
          </FormControl>
        </Box>

        <Box w={"100%"} pl={0} pr={4} mt={4}>
          <Button
            mb={5}
            size={"md"}
            height="2.75rem"
            variantColor="blue"
            onClick={() => {
              dispatch(
                asyncPostAccident({
                  date: DateTime.fromJSDate(accidentDate).toSQLDate(),
                  description: descriptionRef.current.value,
                })
              );

              descriptionRef.current.value = "";
              setAccidentDate("");
            }}
          >
            {_("Add")}
          </Button>
        </Box>
      </Flex>
    </>
  );
};

export default Accidents;
