import store from "../store";

// const localizationDict: any = {
//   fi_FI: {
//     Add: "Lisää",
//     Date: "Päivämäärä",
//     Description: "Kuvaus",
//     Accidents: "Tapaturmat",
//     "Add an accident": "Lisää tapaturma",
//     "Accident free days": "Tapaturmatonta päivää",
//   },
// };

// eslint-disable-next-line no-shadow-restricted-names
export function _(...args: string[]): string {
  const locale = store.getState().worksite.locale;
  const localizationDict = store.getState().worksite.l10n;
  const language = locale;

  if (args.length === 0) {
    return "";
  }
  var text = args[0];

  if (
    typeof localizationDict !== "undefined" &&
    typeof language !== "undefined"
  ) {
    if (
      localizationDict.hasOwnProperty(language) &&
      typeof localizationDict[language][args[0]] !== "undefined"
    ) {
      text = localizationDict[language][args[0]];
    }
  }
  // replace %s's
  var loc = text.search("%s");
  var num = 1;
  while (loc !== -1) {
    var replacement = "";
    if (typeof args[num] !== "undefined") {
      replacement = args[num];
    }
    text = text.replace("%s", replacement);
    loc = text.search("%s");
    ++num;
  }
  // replace mustaches
  var regex = /:[^}]*}}/g; // remove instructions
  text = text.replace(regex, "}}");
  for (var i = 1; i < args.length; i++) {
    text = text.replace("{{" + i + "}}", args[i]);
  }
  return text;
}

export default _;
