import { Flex, Box, Stack, Divider, Text } from "@chakra-ui/core";
import { DateTime } from "luxon";
import React from "react";
import { useSelector } from "react-redux";
import _ from "../lib/localisation";

import { RootState } from "../store";

const Header = () => {
  const startDate = useSelector(
    (state: RootState) => state.worksite.worksite.startDate
  );
  const lastAccident = useSelector(
    (state: RootState) => state.worksite.accidents.slice(-1)[0]
  );
  const holidays = useSelector((state: RootState) => state.worksite.holidays);
  const name = useSelector((state: RootState) => state.worksite.worksite.name);

  const calculateAccidentFreeDays = () => {
    // depending on if last accident has happened before startdate
    let actualStartdate = DateTime.fromJSDate(startDate);

    if (!!lastAccident) {
      if (
        DateTime.fromISO(lastAccident.created_at).toMillis() >
        DateTime.fromJSDate(startDate).toMillis()
      ) {
        actualStartdate = DateTime.fromISO(lastAccident.created_at);
        console.log("acutalstartdate is accident");
      }
    }

    // reduce holidays

    let freeDays = 0;

    for (
      let currDate = actualStartdate;
      currDate.toMillis() < DateTime.local().toMillis();
      currDate = currDate.plus({ days: 1 })
    ) {
      let workdayFlag = false;

      for (let holiday = 0; holiday < holidays.length; holiday++) {
        // check if any workdays are coming to this specific workday

        const { startDate, endDate, workday } = holidays[holiday];

        if (
          DateTime.fromJSDate(startDate).toMillis() <= currDate.toMillis() &&
          currDate.toMillis() <
            DateTime.fromJSDate(endDate).plus({ days: 1 }).toMillis() &&
          workday
        ) {
          workdayFlag = true;
          break;
        }
      }

      if (workdayFlag === false) {
        for (let holiday = 0; holiday < holidays.length; holiday++) {
          // check if current day is in this holiday range

          const { startDate, endDate } = holidays[holiday];

          if (
            DateTime.fromJSDate(startDate).toMillis() <= currDate.toMillis() &&
            currDate.toMillis() <
              DateTime.fromJSDate(endDate).plus({ days: 1 }).toMillis()
          ) {
            freeDays += 1;
            break;
          }
        }
      }
    }

    // Then calculate basic diff

    return (
      Math.floor(DateTime.local().diff(actualStartdate, "days").days) - freeDays
    );
  };

  return (
    <Flex
      maxWidth="40rem"
      margin="auto"
      mt={"4rem"}
      alignItems="center"
      justifyContent="flex-start"
    >
      <Flex
        justifyContent="space-around"
        alignItems="center"
        flex="row"
        bg="gray.50"
        p={4}
        rounded="99rem"
        my="1rem"
        mx="1rem"
        shadow="lg"
        width={["7rem", "9rem"]}
        height={["7rem", "9rem"]}
        textAlign="center"
      >
        <Box>
          <Text m={0} fontSize={["3rem", "4rem"]} lineHeight={["3rem", "4rem"]}>
            {calculateAccidentFreeDays()}
          </Text>
          <Text m={0} fontSize={["0.75rem", "1rem"]}>
            {_("Accident free days")}
          </Text>
        </Box>
      </Flex>
      <Box p={4} mb="1rem" textAlign="left">
        <Stack spacing={2}>
          <Text fontWeight="bold">{_("Site")}</Text>
          <Divider width={"4rem"} borderColor="black" />
          <Text fontSize="2xl">{name}</Text>
        </Stack>
      </Box>
    </Flex>
  );
};

export default Header;
