import theme from "@chakra-ui/core/dist/theme";

const customTheme = {
  ...theme,
  fonts: {
    body: "system-ui, sans-serif",
    heading: "system-ui, sans-serif",
    mono: "Menlo, monospace",
  },
};

export default customTheme;
