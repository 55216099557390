import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";

import interactionPlugin from "@fullcalendar/interaction"; // for selectable
import dayGridPlugin from "@fullcalendar/daygrid";
import fiLocale from "@fullcalendar/core/locales/fi";
import enLocale from "@fullcalendar/core/locales/en-gb";
import "./fullcalendar-custom.css";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";
import DatePicker from "react-datepicker";
import fi from "date-fns/locale/fi";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/core";
import {
  asyncDeleteHoliday,
  asyncPostHoliday,
} from "../reducers/worksiteReducer";
import { DateTime } from "luxon";
import { registerLocale } from "react-datepicker";
import _ from "../lib/localisation";

registerLocale("fi", fi);

const FullCalendarView = () => {
  const holidays = useSelector(
    (state: RootState) => state.worksite.holidays
  ).map((i) => {
    return {
      start: i.startDate,
      end: i.endDate,
      title: i.description,
      id: i.id,
      static: i.static,
      workday: i.workday,
    };
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const startDate = useSelector(
    (state: RootState) => state.worksite.worksite.startDate
  );

  const deleteModal = useDisclosure();
  const isOpen2 = deleteModal.isOpen;
  const onOpen2 = deleteModal.onOpen;
  const onClose2 = deleteModal.onClose;

  const initialRef = React.useRef<any>();
  const radioRef = React.useRef<any>();
  const [radioValue, setRadioValue] = React.useState<string>("0");

  const [calendarSelect, setCalendarSelect] = useState<{
    startStr: string;
    endStr: string;
  }>({
    startStr: DateTime.fromJSDate(new Date()).toSQLDate(),
    endStr: DateTime.fromJSDate(new Date()).toSQLDate(),
  });
  const dispatch = useDispatch();

  const locale = useSelector((state: RootState) => state.worksite.locale);

  const [deleteModalContents, setModalContents] = useState<{
    name: string;
    id: string;
    start: string;
    end: string;
  }>({ name: "", id: "", start: "", end: "" });

  return (
    <Box>
      <Text fontSize="xl" mt={0} fontWeight="bold">
        {_("Start date")}
      </Text>
      <Box mt={4}>{DateTime.fromJSDate(startDate).toFormat("dd.MM.yyyy")}</Box>
      <Divider my={8} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Text fontSize="xl" mt={0} fontWeight="bold" p={4}>
          {_("Exception handling")}
        </Text>

        <Button
          variantColor="blue"
          ml={4}
          onClick={() => {
            setCalendarSelect({
              startStr: DateTime.fromJSDate(new Date()).toSQLDate(),
              endStr: DateTime.fromJSDate(new Date()).toSQLDate(),
            });
            onOpen();
          }}
        >
          {_("Add exception")}
        </Button>
      </Box>
      <Box mt={8}>
        <FullCalendar
          displayEventTime={false}
          plugins={[dayGridPlugin, interactionPlugin]}
          selectable={true}
          weekNumberCalculation="ISO"
          height={"auto"}
          locale={locale === "en_GB" ? enLocale : fiLocale}
          editable={true}
          events={holidays.map((i) => {
            return {
              ...i,
              color: i.static ? "gray" : i.workday ? "#B31CFA" : "#3182ce",
              editable: i.static ? false : true,
            };
          })}
          eventClick={(e) => {
            if (e.event.backgroundColor === "gray") {
              return;
            }

            setModalContents({
              name: e.event.title,
              id: e.event.id,
              start: e.event.startStr,
              end: e.event.endStr,
            });
            onOpen2();
          }}
          initialView="dayGridMonth"
          select={(arg) => {
            console.log("arg", arg);
            setCalendarSelect({
              ...arg,
              endStr: DateTime.fromJSDate(arg.end)
                .minus({ days: 1 })
                .toSQLDate(),
            });
            onOpen();
          }}
        />
        <Box>
          <Flex>
            <Box
              as="div"
              margin="7px"
              rounded="1rem"
              w="10px"
              h="10px"
              bg="#3182ce"
            ></Box>{" "}
            {_("Worksite closed")}
          </Flex>
          <Flex>
            <Box
              as="div"
              margin="7px"
              rounded="1rem"
              w="10px"
              h="10px"
              bg="#B31CFA"
            ></Box>{" "}
            {_("Worksite forced open")}
          </Flex>
          <Flex>
            <Box
              as="div"
              margin="7px"
              rounded="1rem"
              w="10px"
              h="10px"
              bg="#888"
            ></Box>{" "}
            {_("Finnish holiday calendar (configure in Valotalive)")}
          </Flex>
        </Box>
        <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{_("Add a new period")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>{_("Start date")}</FormLabel>
                <DatePicker
                  locale="fi"
                  dateFormat="dd.MM.yyyy"
                  selected={DateTime.fromSQL(
                    calendarSelect?.startStr
                  ).toJSDate()}
                  onChange={(date: any) => {
                    if (date instanceof Date) {
                      if (
                        // also handle when endstr is smaller than startstring
                        DateTime.fromJSDate(date).toMillis() >
                        DateTime.fromSQL(calendarSelect.endStr).toMillis()
                      ) {
                        setCalendarSelect({
                          endStr: DateTime.fromJSDate(date).toSQLDate(),
                          startStr: DateTime.fromJSDate(date).toSQLDate(),
                        });
                      } else {
                        setCalendarSelect({
                          endStr: calendarSelect.endStr,
                          startStr: DateTime.fromJSDate(date).toSQLDate(),
                        });
                      }
                    }
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{_("End date")}</FormLabel>
                <DatePicker
                  locale="fi"
                  dateFormat="dd.MM.yyyy"
                  selected={DateTime.fromSQL(calendarSelect?.endStr).toJSDate()}
                  onChange={(date: any) => {
                    if (date instanceof Date) {
                      if (
                        // also handle when endstr is smaller than startstring
                        DateTime.fromJSDate(date).toMillis() <
                        DateTime.fromSQL(calendarSelect.startStr).toMillis()
                      ) {
                        setCalendarSelect({
                          endStr: DateTime.fromJSDate(date).toSQLDate(),
                          startStr: DateTime.fromJSDate(date).toSQLDate(),
                        });
                      } else {
                        setCalendarSelect({
                          startStr: calendarSelect.startStr,
                          endStr: DateTime.fromJSDate(date).toSQLDate(),
                        });
                      }
                    }
                  }}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>{_("Reason")}</FormLabel>
                <Input
                  ref={initialRef}
                  placeholder={_("Weekend, national holiday, other reason")}
                />
              </FormControl>
              <RadioGroup
                defaultValue="0"
                onChange={(i, j) => {
                  console.log(j);
                  if (!!j) {
                    setRadioValue(j.toString());
                  }
                }}
                ref={radioRef}
              >
                <Stack mt={4}>
                  <Radio name="1" value="0" defaultIsChecked={true}>
                    {_("Close the worksite for the selected period")}
                  </Radio>
                  <Radio name="1" value="1">
                    {_(
                      "Override all holidays and force the worksite open for the selected period"
                    )}
                  </Radio>
                </Stack>
              </RadioGroup>
            </ModalBody>

            <ModalFooter>
              <Button
                variantColor="blue"
                mr={3}
                onClick={() => {
                  console.log("workday");
                  console.log("radioValue", radioValue);

                  //todo get workday and put it to the request

                  dispatch(
                    asyncPostHoliday({
                      start: calendarSelect ? calendarSelect.startStr : "",
                      end: calendarSelect ? calendarSelect.endStr : "",
                      description: initialRef.current.value,
                      workday: radioValue,
                    })
                  );

                  onClose();
                }}
              >
                {_("Save")}
              </Button>
              <Button onClick={onClose}>{_("Cancel")}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isOpen2} onClose={onClose2}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{deleteModalContents.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>{_("Start Date")}</FormLabel>
                {deleteModalContents.start.slice(0, 10)}
              </FormControl>
              <FormControl>
                <FormLabel>{_("End Date")}</FormLabel>
                {deleteModalContents.end.slice(0, 10)}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                variantColor="red"
                mr={3}
                onClick={() => {
                  dispatch(
                    asyncDeleteHoliday(parseInt(deleteModalContents.id))
                  );
                  onClose2();
                }}
              >
                {_("Delete")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};

export default FullCalendarView;
