import React, { useEffect } from "react";
import ThemeProvider from "@chakra-ui/core/dist/ThemeProvider";
import customTheme from "./theme";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Box, CSSReset, Spinner } from "@chakra-ui/core";
import "./App.css";
import "./components/fullcalendar.css";
import store, { RootState } from "./store";
import ControlTabs from "./components/ControlTabs";
import Header from "./components/Header";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import Footer from "./components/Footer";
import getWorksiteId from "./lib/getWorksiteIdFromUrl";
import {
  setUrlParams,
  asyncGetWorksite,
  asyncGetLocale,
} from "./reducers/worksiteReducer";

const RedirectView = () => {
  useEffect(() => {
    // Simulate a mouse click:
    window.location.href = "https://valota.live";
  }, []);

  return <div></div>;
};

const Main = () => {
  const backgroundColor = useSelector(
    (state: RootState) => state.worksite.worksite.backgroundColor
  );

  const dispatch = useDispatch();
  const { worksiteId, userId } = useParams<{
    worksiteId: string;
    userId: string;
  }>();

  useEffect(() => {
    dispatch(setUrlParams({ worksiteId, userId }));
    dispatch(asyncGetWorksite(getWorksiteId()));
    dispatch(asyncGetLocale());
  }, [dispatch, userId, worksiteId]);

  const loading = useSelector((state: RootState) => state.worksite.loading);
  const loadingL10n = useSelector(
    (state: RootState) => state.worksite.loadingL10n
  );

  const error = useSelector((state: RootState) => state.worksite.error);

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
  }, [backgroundColor]);

  if (loading || loadingL10n) {
    return (
      <Box mx={"auto"} pt={16} display="flex" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  if (!loading && error) {
    return (
      <Box maxW={"40em"} p={"1rem"} margin={"auto"}>
        <h1>
          Unfortunately you do not have access to this site. Please contact your
          Valotalive admin
        </h1>
      </Box>
    );
  }

  return (
    <>
      <CSSReset />
      <Header />
      <ControlTabs />
      <Footer />
    </>
  );
};

const DemoApp = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={customTheme}>
        <Router basename={"https://" + process.env.REACT_APP_DOMAIN + "/"}>
          <Switch>
            <Route path="/accidentcounter/:worksiteId/:userId">
              <Main />
            </Route>
            <Route path="*">
              <RedirectView />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default DemoApp;
